import * as React from 'react';
import { GetNextPlansYear } from '@coverright/utils';
import { useState } from 'react';
import Breakdown from './components/breakdown';
import { Props } from '../lib/types';
import useRegulatoryVariable from '../api/use-regulatory-variable';
import { usePdpPlanDiscountsQuery } from '@coverright/data-access/medicare';
import { Preloader } from '@coverright/features';

export function PDPDrugCostBreakdown(props: Props & { clientId?: string }) {
  const [fullYear, setFullYear] = useState(
    props.planYear === GetNextPlansYear()
  );
  const regulatory = useRegulatoryVariable(props.planYear);
  const { data, loading, previousData } = usePdpPlanDiscountsQuery({
    variables: { ...props, fullYear },
    errorPolicy: 'all'
  });

  const discounts = data?.pdpPlanDiscounts || previousData?.pdpPlanDiscounts;

  if (!discounts && loading) {
    return <Preloader sx={{ mt: 4, mb: 6 }} />;
  }

  if (!discounts) {
    return null;
  }

  return (
    <Breakdown
      plan={discounts}
      loading={loading}
      fullYear={fullYear}
      regulatory={regulatory}
      onFullYearChange={setFullYear}
      variant={props.variant}
    />
  );
}
