import React, { forwardRef } from 'react';
import { InputProps, TextInput } from './text-input/text-input';
import { InputMask, InputMaskProps, Track } from '@react-input/mask';

const ForwardedInputMask = forwardRef<HTMLInputElement, InputMaskProps>((props, forwardedRef) => {
  const track: Track = ({ inputType, value, data, selectionStart, selectionEnd }) => {

    if (inputType === 'insert' && !/^\D*1/.test(data) && selectionStart <= 1) {
      return `1${data}`;
    }

    if (inputType !== 'insert' && selectionStart <= 1 && selectionEnd < value.length) {
      if (selectionEnd > 2) {
        return '1';
      }
      if (selectionEnd === 2) {
        return false;
      }
    }

    return data;
  };

  return <InputMask ref={forwardedRef}
                    track={track}
                    mask="+_ (___) ___-____"
                    replacement={{ _: /\d/ }}
                    {...props} />
});

interface Props extends InputProps {
  selectAllOnFocus?: boolean
}

export const PhoneNumberInput = forwardRef((props: Props, ref) => {
  const {selectAllOnFocus, component, ...rest} = props;

  const Component: any = component ? component : TextInput;

  return <Component
    ref={ref}
    valid={/\+1 \(\d{3}\) \d{3}-\d{4}/gm.test(String(props.value))}
    {...rest}
    data-test={'phone-number-input'}
    InputProps={{
      ...props.InputProps,
      inputComponent: ForwardedInputMask,
      onFocus: (e: any) => {
        selectAllOnFocus && e.target.select();
        props.InputProps?.onFocus && props.InputProps.onFocus(e);
      }
    }}
  />
})

export const formatToPhoneNumber = (value?: string | null) => {
  if (!value) {
    return '';
  }

  if (value.startsWith('+1')) {
    return `+1 (${value.substring(2, 5)}) ${value.substring(5, 8)}-${value.substring(8, 12)}`
  } else if (value.startsWith('1') && /\d{11}/.test(value)) {
    return `+1 (${value.substring(1, 4)}) ${value.substring(4, 7)}-${value.substring(7, 11)}`
  } else if (/\d{10}/.test(value)) {
    return `+1 (${value.substring(0, 3)}) ${value.substring(3, 6)}-${value.substring(6, 10)}`
  }

  return value;
};
